<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <div class="ml-1 mt-50 text-secondary">
        Antecedentes personales de el/la apoderado(a).
      </div>
      <div class="mr-1 mt-50 text-muted small">Campos obligatorios <span class="text-danger">*</span></div>
    </div>

    <!-- FORM -->
    <b-overlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="#000000"
    >
      <b-row class="ml-25 mr-25">
        <b-col cols="6" >
          <!-- INFO PERSONAL -->
          <b-row>

            <!-- RUT -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="rut"
              >
                <template #label>
                  RUT <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="rut"
                  v-model="apoderado.rut"
                  placeholder="Ej: 18.933.693-3"
                  :state="v$.apoderado.rut.$error === true
                    ? false
                    : null"
                  :autofocus="crud === 'c'"
                  @blur="buscarRuts()"
                  @keyup="apoderado.rut = formatRut(apoderado.rut)"
                />

                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.rut.$error"
                  id="rutInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.apoderado.rut.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- NOMBRE -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label="Nombre *"
                label-for="nombre"
              >
                <template #label>
                  Nombre <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="nombre"
                  v-model="apoderado.nombre"
                  placeholder="Ingresa el nombre"
                  :state="v$.apoderado.nombre.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.nombre.$error"
                  id="nombreInfo"
                >
                  <p
                    v-for="error of v$.apoderado.nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SEGUNDO NOMBRE -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label="Segundo nombre"
                label-for="segundo_nombre"
              >
                <b-form-input
                  id="segundo_nombre"
                  v-model="apoderado.segundo_nombre"
                  placeholder="Ingresa el segundo nombre"
                  :state="v$.apoderado.segundo_nombre.$error === true
                  ? false
                  : null"
                  @input.native="v$.apoderado.segundo_nombre.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.segundo_nombre.$error"
                  id="segundo_nombreInfo"
                >
                  <p
                    v-for="error of v$.apoderado.segundo_nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- PRIMER APELLIDO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="primer_apellido"
              >
                <template #label>
                  Apellido paterno <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="primer_apellido"
                  v-model="apoderado.primer_apellido"
                  placeholder="Ingresa el apellido paterno"
                  :state="v$.apoderado.primer_apellido.$error === true
                  ? false
                  : null"
                  @input.native="v$.apoderado.primer_apellido.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.primer_apellido.$error"
                  id="primer_apellidoInfo"
                >
                  <p
                    v-for="error of v$.apoderado.primer_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SEGUNDO APELLIDO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="segundo_apellido"
              >
                <template #label>
                  Apellido materno <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="segundo_apellido"
                  v-model="apoderado.segundo_apellido"
                  placeholder="Ingresa el apellido paterno"
                  :state="v$.apoderado.segundo_apellido.$error === true
                  ? false
                  : null"
                  @input.native="v$.apoderado.segundo_apellido.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.segundo_apellido.$error"
                  id="segundo_apellidoInfo"
                >
                  <p
                    v-for="error of v$.apoderado.segundo_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row>

            <!-- FECHA DE NACIMIENTO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="fecha_nacimiento"
              >
                <template #label>
                  Fecha nacimiento <span class="text-danger">*</span>
                </template>
                <flat-pickr
                  v-model="apoderado.fecha_nacimiento"
                  class="form-control"
                  :config="config.configFlatPickr"
                  v-mask="'##-##-####'"
                  placeholder="dd-mm-YYYY"
                  name="date"
                  autocomplete="off"
                  :class="v$.apoderado.fecha_nacimiento.$error === true
                    ? 'form-control border-danger rounded'
                    : 'form-control'"
                />
                <!-- Validaciones -->
                <div
                  v-if="v$.apoderado.fecha_nacimiento.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.apoderado.fecha_nacimiento.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- GENERO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="genero"
              >
                <template #label>
                  Genero <span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="apoderado.genero"
                  placeholder="Selecciona un genero"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.genero"
                  :options="optionsGeneros"
                  :class="v$.apoderado.genero.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- :disabled="optionsGeneros.length === 0" -->
                <div
                  v-if="v$.apoderado.genero.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.apoderado.genero.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- CORREO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="correo"
              >
                <template #label>
                  Correo <span class="text-danger">*</span>
                </template>
                <b-input-group
                  :class="v$.apoderado.correo.$error === false
                    ? ''
                    : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="correo"
                    v-model="apoderado.correo"
                    placeholder="paz.alv@gmail.com"
                    :class="v$.apoderado.correo.$error === false
                      ? ''
                      : 'border-danger rounded-right'"
                    :state="v$.apoderado.correo.$error === true
                      ? false
                      : null"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.correo.$error"
                  id="correoInfo"
                >
                  <p
                    v-for="error of v$.apoderado.correo.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- CELULAR -->
            <b-col class="mb-25" cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="celular"
              >
                <template #label>
                  Celular <span class="text-danger">*</span>
                </template>
                <b-input-group
                  :class="v$.apoderado.celular.$error === false ? '' : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="celular"
                    v-model="apoderado.celular"
                    placeholder="56978717595"
                    :state="v$.apoderado.celular.$error === true
                    ? false
                    : null"
                    @keyup='apoderado.celular = formatSoloNumerosMaxLenght(apoderado.celular, 11)'
                    @input.native="v$.apoderado.celular.$touch"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.celular.$error"
                  id="celularInfo"
                >
                  <p
                    v-for="error of v$.apoderado.celular.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
      <b-tabs class="primary border rounded pt-50 pb-2">
        
        <!-- Direccion -->
        <b-tab lazy active>
          <template #title>
            <feather-icon
              icon="MapPinIcon"
              size="16"
              class="mr-0 mr-sm-50"
              :class="{'text-danger': hasDireccionErrors}"
            />
            <span 
              class="d-none d-sm-inline"
              :class="{'text-danger': hasDireccionErrors}"
            >
              Dirección
            </span>
          </template>

          <col-linea style="margin-top: -15px !important;" />
          
          <b-row class="mt-3">
            <b-col cols="6">
              <b-row>

                <!-- COMUNA -->
                <b-col cols="12" >
                  <b-form-group
                    label-class="font-weight-bold mt-25 text-right"
                    content-cols="9"
                    label="Comuna *"
                    label-for="id_comuna"
                  >
                    <template #label>
                      Comuna <span class="text-danger">*</span>
                    </template>
                    <v-select
                      v-if="configs.loadingComunas"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      placeholder="Cargando comunas..."
                      :disabled="true"
                    />
                    <v-select
                      v-else
                      v-model="apoderado.id_comuna"
                      placeholder="Selecciona una comuna..."
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nombre"
                      :reduce="option => option.id_comuna"
                      :options="optionsComunas"
                      :class="v$.apoderado.id_comuna.$error === true
                        ? 'border-danger rounded'
                        : ''"
                      :disabled="optionsComunas.length === 0"
                    />
                    <div
                      v-if="v$.apoderado.id_comuna.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.apoderado.id_comuna.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Nombre CALLE -->
                <b-col cols="12">
                  <b-form-group
                    content-cols="9"
                    label-class="font-weight-bold mt-25 text-right"
                    label-for="nombre_calle"
                  >
                    <template #label>
                      Calle <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="nombre_calle"
                      v-model="apoderado.nombre_calle"
                      placeholder="Av. General Borgoño"
                      :state="v$.apoderado.nombre_calle.$error === true
                      ? false
                      : null"
                      @input.native="v$.apoderado.nombre_calle.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.nombre_calle.$error"
                      id="nombre_calleInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.nombre_calle.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>

                <!-- NUMERO -->
                <b-col cols="12">
                  <b-form-group
                    content-cols="9"
                    label-class="font-weight-bold mt-25 text-right"
                    label-for="numero"
                  >
                    <template #label>
                      Número <span class="text-danger">*</span>
                    </template>
                    <b-input-group
                      :class="v$.apoderado.numero.$error === false
                        ? ''
                        : 'is-invalid'"
                    >
                      <b-input-group-prepend is-text>
                        #
                      </b-input-group-prepend>
                      <b-form-input
                        id="numero"
                        v-model="apoderado.numero"
                        placeholder="1305"
                        :state="v$.apoderado.numero.$error === true
                        ? false
                        : null"
                        @keyup='apoderado.numero = formatSoloNumerosMaxLenght(apoderado.numero, 8)'
                        @input.native="v$.apoderado.numero.$touch"
                      />
                    </b-input-group>
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.numero.$error"
                      id="numeroInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.numero.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup,
  BCardSubTitle, BInputGroup, BFormInvalidFeedback, BInputGroupPrepend, BButtonGroup, BOverlay,
  BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'

import { VueMaskDirective } from 'v-mask';

import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

// MIXINS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// Componentes reciclados
import colLinea from '../../../components/Form/colLinea.vue';
import btnSubmit from '../../../components/Form/btnSubmit.vue';

export default {
  directives: {
    mask: VueMaskDirective,
  },
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup,
    BCardSubTitle, BInputGroup, BFormInvalidFeedback, BInputGroupPrepend, BButtonGroup, BOverlay,
    BTabs, BTab,
    vSelect,
    flatPickr,

    // Componentes reciclados
    colLinea,
    btnSubmit,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // data
      configs: {
        cargando: true,
        loadingComunas: true,
      },

      // options
      optionsComunas: [],
      optionsPais: [
        {
          id_pais: 45,
          nombre: 'Chile',
        }
      ],
      apoderado: {
        rut: '',
        nombre: '',
        segundo_nombre: '',
        primer_apellido: '',
        segundo_apellido: '',
        fecha_nacimiento: '',
        genero: '',
        correo: '',
        celular: '',
        nombre_calle: '',
        numero: '',
        id_pais: 45,
      },
      // otros
      config: {
        time: null,
        wrap: true,
        noCalendar: false,
        configFlatPickr: {
          locale: 'es',
          allowInput: true,
          enableTime: false,
          dateFormat: 'd-m-Y',
        },
        showMonths: 1,
      },
    }
  },
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    crud: {
      type: String,
      required: true,
    },
  },
  watch: {
    matricula() {
      this.initialize()
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getComunas: 'ceds/getComunas',
      getPersonasRol: 'personas/getPersonasRol',
    }),
    ...mapState({
      optionsGeneros: state => state.generos.generos
    }),
    hasDireccionErrors() {
      return this.v$.apoderado.nombre_calle.$error || this.v$.apoderado.numero.$error;
    },
  },
  mounted() {
    this.initialize()
    this.cargaComunas()
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt',
      fetchPersonaRut: 'personas/fetchPersonaRut',
      fetchIdPersonasRol: 'personas/fetchIdPersonasRol',
      createPersona: 'personas/addPersona',
      updatePersona: 'personas/updatePersona',
      addMatricula: 'matriculas/addMatricula',
      fetchComunas: 'ceds/fetchComunas',
    }),
    ...mapMutations('matriculas', ['setMatricula']),
    async initialize() {
      this.configs.cargando = true
      if (this.crud === 'u') {
        await this.cargaApoderado()
      } else {
        this.matricula.apoderado.rut = ''
        this.apoderado = this.matricula.apoderado
      }
      this.configs.cargando = false
    },
    async cargaComunas() {
      this.configs.loadingComunas = true
      await this.fetchComunas()
      this.optionsComunas = []
      this.getComunas.forEach(comuna => {
        this.optionsComunas.push({
          id_comuna: comuna.RefCountyId,
          nombre: comuna.Description,
        })
      })
      this.configs.loadingComunas = false
    },
    async cargaApoderado() {
      await this.fetchIdPersonasRol(this.matricula.id_persona_rol_apoderado)
      const rut_completo = this.formatRut(this.getPersonasRol.rut + '' + this.getPersonasRol.dv)
      const comuna = this.getComunas.find(c => c.RefCountyId === this.getPersonasRol.id_comuna)
      const id_comuna = typeof comuna === 'undefined' ? null : this.getPersonasRol.id_comuna
      this.apoderado = {
        // persona
        id_persona: this.getPersonasRol.id_persona,
        id_persona_rol: this.getPersonasRol.id_persona_rol,
        rut: rut_completo,
        dv: this.getPersonasRol.dv,
        nombre: this.getPersonasRol.nombre,
        segundo_nombre: this.getPersonasRol.segundo_nombre,
        primer_apellido: this.getPersonasRol.primer_apellido,
        segundo_apellido: this.getPersonasRol.segundo_apellido,
        genero: this.getPersonasRol.genero,
        fecha_nacimiento: this.formatoDiaMesYear(this.getPersonasRol.fecha_nacimiento),

        // dirección
        celular: this.getPersonasRol.celular,
        correo: this.getPersonasRol.correo,
        id_comuna,
        nombre_calle: this.getPersonasRol.nombre_calle,
        numero:this.getPersonasRol.numero,
        block: this.getPersonasRol.block,
        departamento:this.getPersonasRol.departamento,

        // escolar
        id_rol: 11, // APODERADO
        id_establecimiento: this.getUser.id_establecimiento,
      }
    },
    async buscarRuts() {
      if (this.apoderado.rut !== '') {
        this.configs.cargando = true
        const rutDividido = this.divideRut(this.apoderado.rut)
        const params = {
          rut: rutDividido.rutSinDv,
          dv: rutDividido.dv,
          rol: 11,
        }
        const response = await this.fetchPersonaRut(params)
        if (!response.status && response) {
          const { rut, ...restoDeDatos } = response;
          this.apoderado = { 
            ...this.apoderado, 
            ...restoDeDatos, 
            id_pais: 45,
          }; 
        } else if (response.status === 'error') {
          const rutActual = this.apoderado.rut;
          this.apoderado = {
            rut: rutActual,
            nombre: '',
            segundo_nombre: '',
            primer_apellido: '',
            segundo_apellido: '',
            fecha_nacimiento: '',
            genero: '',
            correo: '',
            celular: '',
            nombre_calle: '',
            numero: '',
            id_pais: 45
          };
        }
        this.configs.cargando = false
      }
    },
    // * SON LLAMADOS DESDE EL PADRE
    validarFormulario() {
      this.v$.apoderado.$touch()
      return !this.v$.apoderado.$invalid;
    },
    getApoderado() {
      const rutDividido = this.divideRut(this.apoderado.rut)
      return {
        ...this.apoderado,
        rut: rutDividido.rutSinDv,
        dv: rutDividido.dv,
        id_rol: 11,
        id_establecimiento: this.getUser.id_establecimiento,
      };
    },
    resetValidations() {
      this.v$.$reset(); 
    },
  },
  validations() {
    return {
      apoderado: {
        rut: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 13 caracteres.', maxLength(13)),
        },
        nombre: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        segundo_nombre: {
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        primer_apellido: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        segundo_apellido: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        genero: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_nacimiento: {
          required: helpers.withMessage('El campo es requerido.', required),
        },

        id_comuna: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        nombre_calle: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        numero: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        correo: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),

          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        celular: {
          maxLength: helpers.withMessage('Debe tener máximo 11 caracteres.', maxLength(11)),
          required: helpers.withMessage('El campo es requerido.', required),
        },
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
.has-error {
  border: 1px solid red;
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>